import React from 'react';

import { Link } from 'react-router';

import { Box, Typography } from '@mui/material';

export default function Links(props) {
  const { icons, close, sitemap } = props;

  return (
    <Box>
      <Box mr={2} height="20px" textAlign="right" sx={{ cursor: 'pointer' }} onClick={close}>
        <img height="100%" src={icons.close} alt="Close" />
      </Box>

      <Box
        height={{ xs: '80vh', lg: '140px' }}
        display="flex"
        flexWrap="wrap"
        alignContent="center"
        flexDirection="column"
      >
        {sitemap.links.map((link) => (
          <Box key={link.path} px={2} height={{ xs: '7%', lg: '25%' }}>
            <Link to={link.path} onClick={close} style={{ textDecoration: 'none' }}>
              <Typography variant="h6" sx={{ ':hover': { color: '#FFF' } }}>
                {link.label}
              </Typography>
            </Link>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
