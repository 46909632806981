import { createTheme } from '@mui/material/styles';

import KHTekaLightWoff2 from './fonts/KHTeka-Light/KHTeka-Light.woff2';
import KHTekaRegularWoff2 from './fonts/KHTeka-Regular/KHTeka-Regular.woff2';
import KHTekaMediumWoff2 from './fonts/KHTeka-Medium/KHTeka-Medium.woff2';
import KHTekaLightExtendedWoff2 from './fonts/KHTekaPro-LightExtended/KHTekaPro-LightExtended.woff2';

let customTheme = createTheme({
  palette: {
    primary: {
      main: '#18181A',
    },
    common: {
      black: '#18181A',
    },
    midGray: {
      main: '#B2B2BA',
    },
    lightGray: {
      main: '#E4E4ED',
      light: '#F5F5F8',
    },
    purple: {
      main: '#C887FF',
      light: '#EBD7FF',
    },
    orange: {
      main: '#FF7841',
      light: '#FFE1D7',
    },
    green: {
      main: '#78DC46',
      light: '#DCFFC8',
    },
  },
  typography: {
    fontFamily: 'KHTeka',
    allVariants: {
      fontFamily: 'KHTeka',
    },
    body1: {
      fontFamily: 'KHTeka',
      fontWeight: 300,
      fontSize: '18px',
      lineHeight: '24px',
    },
    h1: {
      fontFamily: 'KHTekaPro',
      fontWeight: 300,
      fontSize: '58px',
      lineHeight: '56px',
    },
    h2: {
      fontFamily: 'KHTekaPro',
      fontSize: '52.5px',
      lineHeight: '63px',
      fontWeight: 300,
    },
    h3: {
      fontFamily: 'KHTekaPro',
      fontWeight: 300,
      fontSize: '48px',
      lineHeight: '56px',
    },
    h4: {
      fontFamily: 'KHTekaPro',
      fontWeight: 300,
      fontSize: '32px',
      lineHeight: '39.5px',
    },
    h5: {
      fontFamily: 'KHTekaPro',
      fontWeight: 300,
      fontSize: '21px',
      lineHeight: '39.5px',
    },
    h6: {
      fontFamily: 'KHTekaPro',
      fontWeight: 300,
      fontSize: '18px',
      lineHeight: '24px',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'KHTeka';
          font-weight: 300;
          font-weight: lighter;
          font-style: normal;
          src: url(${KHTekaLightWoff2}) format('woff2'), url(${KHTekaLightWoff2}) format('woff');
        }
        @font-face {
          font-family: 'KHTeka';
          font-weight: 400;
          font-weight: normal;
          font-style: normal;
          src: url(${KHTekaRegularWoff2}) format('woff2'), url(${KHTekaRegularWoff2}) format('woff');
        }
        @font-face {
          font-family: 'KHTeka';
          font-weight: 500;
          font-weight: bold;
          font-style: normal;
          src: url(${KHTekaMediumWoff2}) format('woff2'), url(${KHTekaMediumWoff2}) format('woff');
        }
        @font-face {
          font-family: 'KHTekaPro';
          font-style: normal;
          src: url(${KHTekaLightExtendedWoff2}) format('woff2'), url(${KHTekaLightExtendedWoff2}) format('woff');
        }

        a {
          color: #18181A;
        }
        a:hover {
          text-decoration: none;
        }
      `,
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            borderRadius: '0px',
            backgroundColor: '#FFF',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '0px',
        },
      },
    },
  },
});

customTheme = createTheme({
  ...customTheme,
});

export default customTheme;
