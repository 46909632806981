import React from 'react';

import { Link } from 'react-router';

import { Box, Stack, Typography } from '@mui/material';

export default function Menu(props) {
  const { big, logo, links, light, icons, logout, onProfileClick, onSitemapClick } = props;

  // SELECT the correct logo version, home, residential and business pages have it white
  // while other pages like offers, contact us and policies pages have it dark
  const logoSrc = light ? logo.light : logo.dark;

  return (
    <Box
      p={2}
      top={0}
      left={0}
      zIndex={10}
      width="100vw"
      display="flex"
      position="absolute"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      {
        // BIG logo shown only on the home page
        big && (
          <Box width={{ xs: '215px', sm: '50%' }}>
            <img src={logoSrc} alt="logo" width="100%" />
          </Box>
        )
      }
      {
        // REGULAR logo shown on all other pages
        !big && (
          <Box height={{ xs: '22.5px', sm: '33.5px', lg: '52px' }}>
            <Link to="/">
              <img src={logoSrc} alt="logo" height="100%" />
            </Link>
          </Box>
        )
      }

      <Stack direction="row" mr={{ xs: 2, sm: 3 }} spacing={6} alignItems="center">
        <Box display={{ xs: 'none', lg: 'block' }}>
          {links.map((link) => (
            <Box key={link.path}>
              <Typography
                to={link.path}
                component={Link}
                variant="h6"
                lineHeight="20px"
                sx={{
                  color: light ? 'white' : 'black.main',
                  textDecoration: 'none',
                  '&:hover': { textDecoration: 'underline', color: light ? 'white' : 'black.main' },
                }}
              >
                {link.label}
              </Typography>
            </Box>
          ))}
        </Box>

        <Box sx={{ cursor: 'pointer' }} width={{ xs: '30px', lg: '35px' }} onClick={onSitemapClick}>
          <img width="100%" alt="Menu" src={light ? icons.menu.light : icons.menu.dark} />
        </Box>

        <Box sx={{ cursor: 'pointer' }} width={{ xs: '30px', lg: '35px' }} onClick={onProfileClick}>
          <img width="100%" alt="Profile" src={light ? icons.profile.light : icons.profile.dark} />
        </Box>

        {logout && (
          <Box sx={{ cursor: 'pointer' }} width={{ xs: '30px', lg: '35px' }} onClick={logout}>
            <img width="100%" alt="Close" src={light ? icons.close.light : icons.close.dark} />
          </Box>
        )}
      </Stack>
    </Box>
  );
}
