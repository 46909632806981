import React from 'react';

import { Box, Stack, Typography, Switch, Collapse, IconButton } from '@mui/material';

import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

export default function CookieType(props) {
  const { open, title, description, expand, checked, onClick } = props;

  return (
    <Box>
      <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton onClick={expand}>
            {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
          </IconButton>

          <Typography variant="h6">{title}</Typography>
        </Stack>

        <Switch disabled checked={checked} onClick={onClick} />
      </Box>

      <Collapse in={open}>
        <Typography fontWeight={300}>{description}</Typography>
      </Collapse>
    </Box>
  );
}
