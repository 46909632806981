import React from 'react';

import { Box, CircularProgress } from '@mui/material';

export default function SpinnerOfDoom() {
  return (
    <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
      <CircularProgress />
    </Box>
  );
}
