import useStore from '../store';

import { PERSONAL_AREA_PATHNAME } from '../routes';

export async function login(params) {
  const { apiUrl, navigate, updateModal, updateProfile, pageRedirect } = params;

  try {
    // GET the API key
    const { error, apiKey } = await getApiKey({ ...params, updateModal });
    if (error) return { error };

    // GET the user profile
    const profile = await getProfile({ apiKey, apiUrl });
    updateProfile(profile);

    const { role } = profile;

    if (role !== 'customer') {
      // REDIRECT users to unauthorized page
      navigate('unauthorized');
    } else {
      // OVERRIDE pageRedirect if passed as a query string parameter
      navigate(pageRedirect || PERSONAL_AREA_PATHNAME);
    }
  } catch (error) {
    console.log(error);
  }
}

export async function logout(apiUrl) {
  const profile = await getProfile({ apiUrl });

  useStore.getState().updateProfile(profile);
}

export async function getApiKey(data) {
  const { apiUrl, username, password } = data;

  const url = `${apiUrl}/api/api_key`;
  const options = {
    method: 'get',
    headers: {
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
      Authorization: `Basic ${btoa(`${username}:${password}`)}`,
    },
  };

  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        const { status, statusText } = response;

        if (status === 401) {
          throw Error('The email address or password you entered is not valid.');
        } else {
          throw Error(statusText);
        }
      }

      return response.json();
    })
    .then((response) => {
      if (response) {
        const { api_key, expires } = response;
        return { apiKey: api_key, expires };
      }
    })
    .catch((error) => {
      const { message } = error;
      return { error: { message } };
    });
}

export async function getProfile({ apiUrl, apiKey }) {
  const apiKeyParam = apiKey ? `?api_key=${apiKey}` : '';
  const url = `${apiUrl}/api/my_profile${apiKeyParam}`;

  return fetch(url)
    .then((response) => {
      if (response.ok) return response.json();
      else throw Error(response.statusText);
    })
    .then((response) => {
      // const { role } = response;

      // if (role === 'autologin') {
      //   // FIXME: Do we really need this?
      //   const objectGroupName = getObjectGroupName(response.objects, apiUrl, apiKeyParam);
      //   return { ...response, objectName: objectGroupName };
      // } else {
      //   return response;
      // }

      return response;
    })
    .catch((error) => {
      console.log('Error fetching profile', error.message);
    });
}

// async function getObjectGroupName(id, apiUrl, apiKeyParam) {
//   return fetch(`${apiUrl}/object?q=id:${id}&fields=object_group.name${apiKeyParam}`)
//     .then((response) => {
//       if (response.ok) return response.json();
//       else throw Error(response.statusText);
//     })
//     .then((response) => {
//       return response.data[0]['object_group.name'];
//     });
// }
