import React, { useState, useEffect } from 'react';

import { Box, Button, Dialog, DialogContent, Stack, Divider, Typography } from '@mui/material';

import { useLocation } from 'react-router';

import CookieFab from './CookieFab';
import CookieType from './CookieType';

import { COOKIE_POLICY_PATHNAME } from '../../routes';

import './style.css';

const injectGAScript = (googleTagManagerContainerId) => {
  // STORE the consent only if is not previously given
  if (!localStorage.getItem('consent')) {
    // TODO for GDPR compliance consent should be stored into DB
    // with an incremental logic 'cause it should be denied and given
    // multiple times from the same user/anonymous and legals has to be able to know exactly when
    localStorage.setItem('consent', `Given on ${new Date().toLocaleString()}`);
  }

  // Add Google Tag scripts
  const GTMHeadScript1 = document.createElement('script');
  GTMHeadScript1.async = true;
  GTMHeadScript1.src = `https://www.googletagmanager.com/gtag/js?id=${googleTagManagerContainerId}`;

  document.head.appendChild(GTMHeadScript1);

  const GTMHeadScript2 = document.createElement('script');
  GTMHeadScript2.innerText =
    'window.dataLayer = window.dataLayer || [];' +
    'function gtag(){dataLayer.push(arguments);}' +
    'gtag("js", new Date());' +
    `gtag('config', '${googleTagManagerContainerId}');`;

  document.head.appendChild(GTMHeadScript2);
};

function CookieBanner(props) {
  const { googleTagManagerContainerId } = props;

  // GET the current location
  const location = useLocation();
  const currentLocation = location.pathname;

  // Mandatory cookies
  const consent = localStorage.getItem('consent');

  // Optional cookies
  const funcitonal = localStorage.getItem('functionalCookie');
  const analytic = localStorage.getItem('analyticCookie');
  const leistung = localStorage.getItem('leistungCookie');
  const werbung = localStorage.getItem('werbungCookie');

  // SET the local states
  const [gtmCookie, setGtmCookie] = useState();
  const [selected, setSelected] = useState(0);
  const [expandCustomization, setExpandCustomization] = useState(false);
  const [showCookieIcon, setShowCookieIcon] = useState(false);

  // Checkboxes
  const [necessaryChecked, setNecessaryChecked] = useState(true);
  const [functionalChecked, setFunctionalChecked] = useState(false);
  const [analyticChecked, setAnalyticChecked] = useState(false);
  const [leistungChecked, setLeistungChecked] = useState(false);
  const [werbungChecked, setWerbungChecked] = useState(false);

  useEffect(() => {
    // Add Google Tag Manager scripts if consent was previously given
    if (consent) {
      injectScript();
      setShowCookieIcon(true);
    } else {
      setGtmCookie(false);
    }

    // Set true optional cookies if there are setted in localSotrage
    funcitonal && setFunctionalChecked(true);
    analytic && setAnalyticChecked(true);
    leistung && setLeistungChecked(true);
    werbung && setWerbungChecked(true);
  }, []);

  const injectScript = () => {
    injectGAScript(googleTagManagerContainerId);

    setGtmCookie(true);
    setShowCookieIcon(true);

    setNecessaryChecked(true);
    setExpandCustomization(false);
  };

  const handleExpandCustomization = () => {
    setExpandCustomization(!expandCustomization);
  };

  const handleNecessaryCheckbox = (event) => {
    event.stopPropagation();
  };

  const handleFunctionalCheckbox = (event) => {
    event.stopPropagation();

    if (functionalChecked) {
      setFunctionalChecked(false);
      localStorage.removeItem('functionalCookie');
    } else {
      setFunctionalChecked(true);
      localStorage.setItem('functionalCookie', true);
    }
  };

  const handleAnalytikCheckbox = (event) => {
    event.stopPropagation();

    if (analyticChecked) {
      setAnalyticChecked(false);
      localStorage.removeItem('analyticCookie');
    } else {
      setAnalyticChecked(true);
      localStorage.setItem('analyticCookie', true);
    }
  };

  const handleLeistungCheckbox = (event) => {
    event.stopPropagation();
    if (leistungChecked) {
      setLeistungChecked(false);
      localStorage.removeItem('leistungCookie');
    } else {
      setLeistungChecked(true);
      localStorage.setItem('leistungCookie', true);
    }
  };

  const handleWerbungCheckbox = (event) => {
    event.stopPropagation();
    if (werbungChecked) {
      setWerbungChecked(false);
      localStorage.removeItem('werbungCookie');
    } else {
      setWerbungChecked(true);
      localStorage.setItem('werbungCookie', true);
    }
  };

  const handleCookieIcon = () => {
    setGtmCookie(false);
    setShowCookieIcon(!setShowCookieIcon);
  };

  const closeBanner = () => {
    // Remove cookie consent if banner is just closed
    consent && localStorage.removeItem('consent');

    setExpandCustomization(false);
    setGtmCookie(true);
    setShowCookieIcon(true);

    manageAllOptionalCookies(false);

    // Select all the cookies that are injected in the website
    const cookies = document.cookie.split(';');
    cookies.length > 0 && deleteAllCookies(cookies);

    // Remove optional cookies if we reject cookies
    localStorage.removeItem('functionalCookie');
    localStorage.removeItem('analyticCookie');
    localStorage.removeItem('leistungCookie');
    localStorage.removeItem('werbungCookie');

    // Remove the created iFrame for GA cookies
    if (document.getElementById('ga-iframe')) {
      document.getElementById('ga-iframe').remove();
    }
  };

  const handleAcceptAll = () => {
    injectScript();

    manageAllOptionalCookies(true);

    // Set checked all optional cookies if we accept all cookies
    localStorage.setItem('functionalCookie', true);
    localStorage.setItem('analyticCookie', true);
    localStorage.setItem('leistungCookie', true);
    localStorage.setItem('werbungCookie', true);

    // reload the page after accepting the cookies to be sure
    // that will be injected
    window.location.reload();
  };

  const handleSavePreference = () => {
    injectScript();

    // reload the page after accepting the cookies to be sure
    // that will be injected
    window.location.reload();
  };

  const manageAllOptionalCookies = (state) => {
    setFunctionalChecked(state);
    setAnalyticChecked(state);
    setLeistungChecked(state);
    setWerbungChecked(state);
  };

  function deleteAllCookies(cookies) {
    const domain = window.location.hostname;
    cookies.forEach((cookie) => {
      document.cookie =
        cookie + `=; Path=/; Domain=${domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    });
  }

  // HIDE the cookie banner if:
  if (
    // 1. the consent has been given
    (consent && !setShowCookieIcon) ||
    // 2. or users are on the cookie-policy page
    currentLocation === COOKIE_POLICY_PATHNAME
  ) {
    return null;
  }

  return (
    <>
      <Dialog open={!gtmCookie} maxWidth="md">
        <DialogContent>
          <Typography variant="h4" mb={2}>
            Cookies bei Opik
          </Typography>

          {/* Short CookieBanner */}
          {!expandCustomization && (
            <>
              <Typography fontWeight={300}>Wir schätzen Ihre Privatsphäre</Typography>

              <Typography fontWeight={300}>
                Wir verwenden Cookies, um Ihr Browsing-Erlebnis zu verbessern, personalisierte
                Werbung oder Inhalte bereitzustellen und unseren Verkehr zu analysieren. Indem Sie
                auf "Alle akzeptieren" klicken, stimmen Sie unserer Verwendung von Cookies zu.
              </Typography>
            </>
          )}

          {/* {Expanded cookieBanner with options to select} */}
          {expandCustomization && (
            <Stack mb={2} spacing={1}>
              <Typography fontWeight={300}>
                Wir verwenden Cookies, um Ihnen eine effiziente Navigation zu ermöglichen und
                bestimmte Funktionen auszuführen. Detaillierte Informationen zu allen Cookies finden
                Sie unter den einzelnen Zustimmungskategorien unten.
              </Typography>

              <Typography fontWeight={300}>
                Die als "Notwendig" kategorisierten Cookies werden in Ihrem Browser gespeichert, da
                sie für die Grundfunktionalitäten der Website unerlässlich sind.
              </Typography>

              <Typography fontWeight={300}>
                Wir verwenden auch Cookies von Drittanbietern, die uns helfen zu analysieren, wie
                Sie diese Website nutzen, Ihre Präferenzen speichern und Inhalte sowie Werbeanzeigen
                bereitstellen, die für Sie relevant sind. Diese Cookies werden nur mit Ihrer
                vorherigen Zustimmung in Ihrem Browser gespeichert.
              </Typography>

              <Typography fontWeight={300}>
                Sie können wählen, ob Sie einige oder alle diese Cookies aktivieren oder
                deaktivieren möchten, aber das Deaktivieren einiger von ihnen kann Ihre
                Browsing-Erfahrung beeinträchtigen.
              </Typography>

              <Divider />

              <CookieType
                title="Notwendig Immer aktiv"
                description="Notwendige Cookies sind erforderlich, um die grundlegenden Funktionen dieser Website zu ermöglichen, wie beispielsweise das sichere Einloggen oder das Anpassen Ihrer Einwilligungspräferenzen. Diese Cookies speichern keine persönlich identifizierbaren Daten."
                open={selected === 0}
                expand={() => setSelected(0)}
                checked={necessaryChecked}
                onClick={handleNecessaryCheckbox}
              />

              <CookieType
                title="Funktional"
                description="Funktionale Cookies helfen, bestimmte Funktionen wie das Teilen des Inhalts der Website auf sozialen Medienplattformen, das Sammeln von Feedback und andere Funktionen Dritter zu erfüllen."
                open={selected === 1}
                expand={() => setSelected(1)}
                checked={functionalChecked}
                onClick={handleFunctionalCheckbox}
              />

              <CookieType
                title="Analytik"
                description="Analytische Cookies werden verwendet, um zu verstehen, wie Besucher mit der Website interagieren. Diese Cookies helfen, Informationen über Metriken wie die Anzahl der Besucher, die Absprungrate, die Verkehrsquelle usw. zu liefern."
                open={selected === 2}
                expand={() => setSelected(2)}
                checked={analyticChecked}
                onClick={handleAnalytikCheckbox}
              />

              <CookieType
                title="Leistung"
                description="Leistungs-Cookies werden verwendet, um zu verstehen und zu analysieren, welche Schlüsselleistungsindizes der Website helfen, eine bessere Benutzererfahrung für die Besucher zu liefern."
                open={selected === 3}
                expand={() => setSelected(3)}
                checked={leistungChecked}
                onClick={handleLeistungCheckbox}
              />

              <CookieType
                title="Werbung"
                description="Werbungs-Cookies werden verwendet, um den Besuchern angepasste Werbeanzeigen basierend auf den zuvor besuchten Seiten zur Verfügung zu stellen und die Wirksamkeit der Werbekampagnen zu analysieren."
                open={selected === 4}
                expand={() => setSelected(4)}
                checked={werbungChecked}
                onClick={handleWerbungCheckbox}
              />
            </Stack>
          )}

          <Box my={3}>
            <Divider />
          </Box>

          <Stack
            mt={2}
            direction="row"
            flexWrap="wrap"
            justifyContent={{ xs: 'left', md: 'right' }}
          >
            <Button sx={{ textTransform: 'capitalize' }} onClick={handleAcceptAll}>
              <Typography variant="h6">Alle akzeptieren &gt;</Typography>
            </Button>

            <Button sx={{ textTransform: 'capitalize' }} onClick={closeBanner}>
              <Typography variant="h6">Alle ablehnen &gt;</Typography>
            </Button>

            <Typography flexGrow={1} />

            {expandCustomization ? (
              <Button sx={{ textTransform: 'capitalize' }} onClick={handleSavePreference}>
                <Typography variant="h6">Meine Präferenzen speichern &gt;</Typography>
              </Button>
            ) : (
              <Button sx={{ textTransform: 'capitalize' }} onClick={handleExpandCustomization}>
                <Typography variant="h6">Anpassen &gt;</Typography>
              </Button>
            )}
          </Stack>
        </DialogContent>
      </Dialog>

      {showCookieIcon && <CookieFab onClick={handleCookieIcon} />}
    </>
  );
}

export { CookieBanner, injectGAScript };
